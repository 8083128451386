/* You can add global styles to this file, and also import other style files */

@import './scss/material';
@import './scss/utils';
@import './scss/responsive';
@import './scss/variables';
@import './scss/theme';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.custom-dialog mat-dialog-container {
    padding: 0px;
    background-color: #efefef;
}

.snackBar{
    background-color: whitesmoke;
    color: black;
}
